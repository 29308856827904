.SecondaryParallaxBanner {
    height: 600px;
    width: 100%;
    position: relative;

    & > * {
      height: 100%;
      width: 100%;
    }

    .mountainBottom {
      height: 42px;
      position: absolute;
      bottom: -42px;
      right: 0;
      width: 100%;
    }
  }