.DefaultPageContainer {
  @apply flex flex-col items-center justify-center mt-menu-height-sm min-h-screen;
  position: relative;

  .WhiteBackground {
    position: absolute;
    width: 100%;
    top: 400px;
    left: 0;
    bottom: 0;
    right: 0;
    background: white;
  }

  .Title {
    @apply text-center px-16;

    h1 {
      @apply text-center mb-4;
    }
  }
}