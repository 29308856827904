:root {
    /* COLORS */
    --rms-color-primary: #f7ab2c;
    --cc-primary-color: var(--rms-color-dark);
    --cc-secondary-color: var(--rms-color-dark);
    --cc-btn-primary-bg: var(--rms-color-primary);
    --cc-btn-primary-color: var(--rms-color-light);
    --cc-btn-primary-hover-bg: var(--rms-color-primary-shade);
    --cc-btn-secondary-bg: var(--rms-color-light-tint);
    --cc-btn-secondary-hover-bg: var(--rms-color-light-shade);
    --cc-cookie-category-block-bg: var(--rms-color-light-tint);
}

.cm__btn[data-role="necessary"] {
    background-color: white !important;
    color: var(--rms-color-dark) !important;
}

.cm__btn[data-role="necessary"]:hover {
    background-color: #eee !important;
}