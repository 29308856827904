.RMSProjectContainer {
  width: 300px;

  .Content {
    .Name {
      @apply text-xl font-bold text-left mt-2 text-color-dark;
      transition: color 0.3s;
      cursor: pointer;

      &:hover {
        @apply text-color-primary;
      }
    }

    .ShortDescription {
      @apply text-left text-color-dark;
    }
  }

  .ImgContainer {
    overflow: hidden;
    cursor: pointer;

    img {
      width: 300px;
      height: 200px;
      object-fit: cover;
      transition: transform 0.3s, filter 0.3s;
    }

    img:hover {
      filter: brightness(80%);
      transform: scale(1.1);
    }
  }
}