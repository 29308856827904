.RMSPagination {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
  padding: 16px;
  justify-content: center;
  align-items: center;

  .Active {
    color: var(--rms-color-primary);
    font-weight: bold;
  }
}