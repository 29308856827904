.RMSTechnologiesRatedContainer {
  width: var(--rms-technologies-rated-container-width, 400px);
  border: solid 2px rgba(var(--rms-color-primary-rgb), 0.5);
  border-top: none;
  border-radius: 8px;
  padding: 8px;
  height: fit-content;
  background: linear-gradient(to bottom, var(--rms-color-primary) 0%, var(--rms-color-primary) 40px, white 42px, white 100%);

  .Title {
    font-weight: bold;
    background: linear-gradient(to top left, var(--rms-color-primary), var(--rms-color-dark));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    height: 40px;
  }
}