.CVBlock {
  background: var(--block-background);
  width: var(--block-width);

  .Title {
    text-align: center;
    margin: 8px;
    @media (max-width: theme("screens.md")) {
      margin: 16px;
    }
  }
}