/* variables.css */

:root {
    --yarl__color_backdrop: #000000ee;
    --yarl__slide_captions_container_background: transparent;
    --yarl__thumbnails_container_background_color: var(--yarl__color_backdrop);
    /* COLORS */
    --rms-color-primary: #f7ab2c;
    --rms-color-primary-rgb: 247,171,44;
    --rms-color-primary-contrast: #000000;
    --rms-color-primary-contrast-rgb: 0,0,0;
    --rms-color-primary-shade: #d99627;
    --rms-color-primary-tint: #f8b341;

    --rms-color-secondary: #b34945;
    --rms-color-secondary-rgb: 179,73,69;
    --rms-color-secondary-contrast: #ffffff;
    --rms-color-secondary-contrast-rgb: 255,255,255;
    --rms-color-secondary-shade: #9e403d;
    --rms-color-secondary-tint: #bb5b58;

    --rms-color-dark: #232325;
    --rms-color-dark-rgb: 35,35,37;
    --rms-color-dark-contrast: #ffffff;
    --rms-color-dark-contrast-rgb: 255,255,255;
    --rms-color-dark-shade: #1f1f21;
    --rms-color-dark-tint: #39393b;

    --rms-color-medium: #49403B;
    --rms-color-medium-rgb: 73,64,59;
    --rms-color-medium-contrast: #ffffff;
    --rms-color-medium-contrast-rgb: 255,255,255;
    --rms-color-medium-shade: #403834;
    --rms-color-medium-tint: #5b534f;

    --rms-color-light: #f7fffb;
    --rms-color-light-rgb: 218,229,223;
    --rms-color-light-contrast: #000000;
    --rms-color-light-contrast-rgb: 0,0,0;
    --rms-color-light-shade: #c0cac4;
    --rms-color-light-tint: #dee8e2;

    --rms-color-white: #fff;

    /* Sizes */
    --rms-size-max-width-container: 1200px;

    /* Font */
    --rms-font-family: "Montserrat", "Helvetica", Arial, sans-serif;

    --rms-color-tertiary: #97B3D0;
    --rms-color-tertiary-rgb: 151,179,208;
    --rms-color-tertiary-contrast: #000000;
    --rms-color-tertiary-contrast-rgb: 0,0,0;
    --rms-color-tertiary-shade: #859eb7;
    --rms-color-tertiary-tint: #a1bbd5;

    --rms-color-success: #37cd7b;
    --rms-color-success-rgb: 55,205,123;
    --rms-color-success-contrast: #000000;
    --rms-color-success-contrast-rgb: 0,0,0;
    --rms-color-success-shade: #30b46c;
    --rms-color-success-tint: #4bd288;

    --rms-color-warning: #e0bc65;
    --rms-color-warning-rgb: 224,188,101;
    --rms-color-warning-contrast: #000000;
    --rms-color-warning-contrast-rgb: 0,0,0;
    --rms-color-warning-shade: #c5a559;
    --rms-color-warning-tint: #e3c374;

    --rms-color-danger: #E94B3C;
    --rms-color-danger-rgb: 233,75,60;
    --rms-color-danger-contrast: #ffffff;
    --rms-color-danger-contrast-rgb: 255,255,255;
    --rms-color-danger-shade: #cd4235;
    --rms-color-danger-tint: #eb5d50;

/** inventory **/
--app-color-items-new: var(--rms-color-success);
--app-color-items-open: var(--rms-color-warning);
--app-color-items-expired: var(--rms-color-danger);

/** spaces **/
--app-space-small: 4px;
--app-space-default: 8px;
--app-space-big: 16px;
--app-space-super-big: 32px;

--app-margin-default: var(--app-space-default);

/** font **/
--app-font-size-normal: 16px;
--app-font-size-small: 14px;
--app-font-size-big: 20px;
--app-font-size-super-big: 24px;

--app-font-size-h1: var(--app-font-size-super-big);
--app-font-size-h2: var(--app-font-size-big);
--app-font-size-h3: var(--app-font-size-normal);
}

@font-face {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: normal;
    src: url('../assets/fonts/montserrat/Montserrat-Medium.ttf');
}

@font-face {
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: normal;
    src: url('../assets/fonts/ubuntu/Ubuntu-Regular.ttf');
}

@font-face {
    font-family: 'Ubuntu';
    font-style: italic;
    font-weight: normal;
    src: url('../assets/fonts/ubuntu/Ubuntu-Italic.ttf');
}

@font-face {
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: bold;
    src: url('../assets/fonts/ubuntu/Ubuntu-Bold.ttf');
}

@font-face {
    font-family: 'Ubuntu';
    font-style: italic;
    font-weight: bold;
    src: url('../assets/fonts/ubuntu/Ubuntu-BoldItalic.ttf');
}

@font-face {
    font-family: 'Handlee';
    font-style: normal;
    font-weight: normal;
    src: url('../assets/fonts/handlee/Handlee-Regular.ttf');
}