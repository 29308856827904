.ProjectPage {
  .TechnologiesContainer {
    margin-top: -24px;
  }

  .ProjectContainer {
    margin-top: 24px;

    .FloatingContainer {

      .ShowCaseButtonContainer {
        button {
          @apply flex flex-row justify-center items-center gap-2;
        }
      }
    }
  }
}