.RMSEmployeesBlock {
  @apply flex flex-col justify-center bg-color-light;

  & > .inner {
    @apply flex flex-col gap-8 items-center justify-center;
  }

  .header {
    @apply flex flex-col justify-center gap-2 items-center;
  }

  .content {
    @apply flex flex-row justify-center flex-wrap gap-8;
  }
}