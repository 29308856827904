.TechnologyPage {
  .TechnologyContainer {
    margin-top: 24px;

    .FloatingContainer {

      .TechnologyGraphicContainer {
        @apply flex flex-row justify-center items-center gap-4;

        padding: 8px;
        border-radius: 8px;
        height: 200px;
        width: 200px;
      }
      .ShowCaseButtonContainer {
        button {
          @apply flex flex-row justify-center items-center gap-2;
        }
      }
    }
  }
}