.RMSPageMetadata {
 width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

 .Date {
   text-align: left;
   color: var(--rms-color-primary);
  font-weight: 200;
 }
}