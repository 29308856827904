.RMSEmployeeContainer {
  width: 200px;

  .Content {
    @apply flex flex-col items-center justify-center;

    .Name {
      @apply text-xl font-bold text-left mt-2 text-color-dark;
      transition: color 0.3s;
      cursor: pointer;

      &:hover {
        @apply text-color-primary;
      }
    }

    .ShortDescription {
      @apply text-left text-color-dark;
    }
  }
}