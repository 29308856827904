.RMSContactBlock {

  .content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    .Employee {
      width: 300px;
      display: flex;
      flex-direction: column;
      gap: 16px;
      align-items: center;

      .ContactElements {
        display: flex;
        flex-direction: column;
        justify-content: left;

        .Social {
          display: flex;
          justify-content: center;
          gap: 16px;
        }
      }
    }
  }

  .Form {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 20px;
    border-radius: 10px;
    gap: 16px;
    max-width: 595px;

    .Inner {
      display: flex;
      flex-direction: column;
      gap: 16px;

      &.Right {
        width: 100%;
      }

      &.Left {
        width: 100%;
        flex-direction: row;
        gap: 16px;

        // media query smaller then 400px
        @media (max-width: 600px) {
          flex-wrap: wrap;

            > * {
                width: 100%;
            }
        }

        .EmailField {
          flex-grow: 1;
        }
      }
    }
  }
}