.FloatingContainer {
  @apply flex flex-col gap-4 items-center;
  max-width: 400px;
  margin: 12px 24px 0 24px;

  &.Left {
    float: left;
  }

  &.Right {
    float: right;
  }

  @media (max-width: theme("screens.md")) {
    max-width: 100%;

    &.Right, &.Left {
      float: none;
    }
  }
}

.Content {

}