.RMSPagePreviewBlock {
  .inner {
    display: flex;
    flex-direction: column;
    gap: 32px;
  }
 .IndexContainer {
   width: 100%;
   display: flex;
   flex-direction: row;
   flex-wrap: wrap;
   gap: 32px;
 }
}