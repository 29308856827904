.RMSPagePreviewElement {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
  width: 416px;
  flex-basis: 350px;
  flex-grow: 1;

  .ImageContainer {
    height: 200px;
    background: var(--rms-color-primary);
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
  }

  .MetaContainer {
    padding: 16px 16px 24px 16px;
    display: flex;
    flex-direction: column;
    gap: 24px;

    .Title {
      text-align: center;
    }

    .Preview {
      gap: 4px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 5;
      -webkit-box-orient: vertical;
    }
  }
}