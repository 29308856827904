.WaveContainer {
  @apply flex flex-col items-center gap-4 mt-16;

  position: relative;
  background: white;
  width: 1000px;
  max-width: 100vw;
  min-height: 100vh;
  margin: -500px auto 44px auto;
  padding: 32px;

  @media (max-width: theme('screens.lg')) {
    margin-top: -600px;
  }

  .TopCurve {
    height: 40px;
    position: absolute;
    top: -35px;
    right: 0;
    left: 0;
    color: white;
  }
}