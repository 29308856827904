.RichTextElementContainer {
  @apply flex flex-col justify-center items-center w-full relative;

  .backgroundImage {
    @apply absolute top-0 bottom-0 left-0 right-0;
    z-index: -1;
  }

  .RichTextElement {
    /*@apply flex flex-col gap-4;*/

    & > * {
      max-width: 100%;
      margin-bottom: 16px;
    }
  }
}