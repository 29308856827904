.CVSection {
  @apply flex flex-col gap-0;
  text-align: left;
  background: var(--section-background-color, transparent);
  width: var(--section-width, 100%);

  .Header, .Content {
    @apply flex flex-row justify-between gap-4;
  }

  .YearContainer {
    width: var(--year-container-width, 60px);
  }

  .TimeLineContainer {
    @apply flex flex-col items-center justify-center gap-0;
    width: 30px;
    height: 30px;

    .StartingPoint {
      width: 100%;
      height: 30px;
      background-color: var(--rms-color-primary);
      border-color: var(--rms-color-dark);
      border-style: solid;
      border-width: 2px;
      border-radius: 0 0 8px 0;
      transform: rotate(45deg);
    }

    .Line {
      width: 2px;
      min-height: 24px;
      background-color: #000;
    }
  }

  .Company, .Content .YearContainer {
    @apply text-sm;
    margin-top: -8px;
    color: var(--rms-color-dark);
  }

  .InformationContainer {
    flex-grow: 1;

    .Time {
      @apply text-xs;
      color: var(--rms-color-medium-tint);
    }

    .Description {

    }
  }

  .Header {
    @apply items-center;

    .YearContainer, .InformationContainer {
      font-weight: bold;
    }
  }

  .Content {
    @apply items-stretch;

    .TimeLineContainer {
      height: auto;
      min-width: 30px;

      .Line {
        height: 100%;
      }
    }
    .InformationContainer {
      @apply text-sm;
      padding-bottom: 24px;
    }
  }
}