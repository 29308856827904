.HomeHeaderBlock {
  @apply w-full max-w-full
  flex flex-col justify-center items-center relative;

  .HomeHeaderBackground {
    height: 100vh;
    width: 100%;

    & > * {
      height: 100%;
      width: 100%;
    }
  }

  .backgroundImage {
    @apply absolute top-0 bottom-0 left-0 right-0;
    z-index: -1;



    img:global {
      @keyframes fadeIn {
        0% {
          opacity: 0; /* Start with full transparency (element is hidden) */
        }
        100% {
          opacity: 1; /* End with full opacity (element is fully visible) */
        }
      }

      opacity: 0;
      animation: bounceInUp 1s, fadeIn 1s ease-in-out forwards, slideRightToLeft 20s linear infinite;
    }

    img {
      position: absolute;
      top: 0;
      bottom: 0;
      height: 100%;
    }

    .backgroundImage_BG {
      animation-delay: 0s;
      max-width: 6000px;
      animation: bounceInUp 1s, fadeIn 1s ease-in-out forwards;
    }

    .backgroundImage_L1 {
      animation-delay: 0.4s;
      max-width: 6000px;
      &:global {
        animation: bounceInUp 1s, fadeIn 1s ease-in-out forwards, slideRightToLeft 60s linear infinite;
        animation-delay: 0.4s;
      }
    }

    .backgroundImage_L2 {
      max-width: 6000px;
      &:global {
        animation: bounceInUp 1s, fadeIn 1s ease-in-out forwards, slideRightToLeft 40s linear infinite;
        animation-delay: 0.8s;
      }
    }

    .backgroundImage_L3 {
      animation-delay: 1.2s;
      max-width: 6000px;
      &:global {
        animation: bounceInUp 1s, fadeIn 1s ease-in-out forwards, slideRightToLeft 20s linear infinite;
        animation-delay: 1.2s;
      }
    }
  }

  .showMoreTextContainer {
    position: fixed;
    z-index: 2;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--rms-color-light);
    padding: 16px;

    &:global {
      animation: fadeInUp 1s;
    }

    .box {
      @apply flex flex-col justify-center items-center text-3xl;

      text-shadow: 0 0 10px theme('colors.color-dark');
      background: var(--rms-color-primary);
      padding: 8px 16px;
      gap: 8px;
      flex-direction: row;
      border-radius: 16px;

      &:global {
        animation: pulse 1.5s infinite;
      }

      @media (max-width: theme('screens.md')) {
        box-shadow: 0 0px 5px #fff;
      }
    }
  }

  .inner {
    @apply flex flex-row-reverse justify-center items-center
    text-center w-full gap-8 p-5 pb-16;

    background: #261f25;
    padding-top: 0;
    margin-top: -50px;
    z-index: 1;

    & > * {
      flex: 1;
    }

    min-height: calc(100vh - theme('spacing.menu-height-sm'));

    .innerRightSide {
      @apply flex flex-col justify-center items-center max-w-screen-xl gap-8;

      .headerContent {
        @apply text-color-light text-xl;

        &:global {
          animation: fadeInRight 1s;
        }

        p {
          @apply text-center;
        }
      }

      h1, h2 {
        @apply text-color-light text-center;
        flex-grow: 1;
      }

      h1 {
        &:global {
          animation: fadeInRight 1s, textPulsate 2s linear infinite alternate;
        }
      }

      h2 {
        @apply font-light;

        margin-top: -24px;

        &:global {
          animation: fadeInUp 1s;
        }
      }

      @media (max-width: theme('screens.md')) {
        @apply gap-14 mt-8;
        flex-grow: unset;
      }
    }

    .HomeHeaderBlock__Screenshot:global {
      width: 300px !important;
      animation: fadeInLeft 1s;
      -webkit-filter: drop-shadow(10px 10px 10px #222);
      filter: drop-shadow(10px 10px 10px #222);
    }

    @media (max-width: theme('screens.md')) {
      @apply flex-col justify-center;
    }
  }

  .mountainBottom {
    height: 42px;
    position: absolute;
    bottom: -40px;
    right: 0;
    width: 100%;
  }
}