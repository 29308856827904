.RatingHorizontalContainer {

  .RatingContainer {
    height: var(--rating-bar-height, 20px);
    width: 100%;
    border-radius: var(--rating-bar-border-radius, 8px);
    border-color: var(--rating-bar-border-color, var(--rms-color-dark));
    border-style: var(--rating-bar-border-style, solid);
    border-width: var(--rating-bar-border-width, 2px);
    overflow: var(--rating-bar-overflow, hidden);

    .RatingBar {
      height: 100%;
      background: var(--rating-bar-background, var(--rms-color-primary));
      color: var(--rating-bar-color, var(--rms-color-white));
      border-radius: var(--rating-bar-border-radius, 8px);
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: left;
      padding-left: 8px;
    }
  }
}